import React, { useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import qrCode from "../../Assets/qr-code.jpg"
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              ABOUT <span className="purple"> US </span>
            </h1>
            <p className="home-about-body">
              <i>
                <b className="purple"> Compass Education Canada (CEC) </b>
              </i>
              was found in 2021 and it is a canadian platform focusing on job seeking for international students. With professional instructors mainly covering in Finance and Accounting fields, long-term core courses for job hunting, and abundant corporate resources at home and abroad, it has become the trusted and preferred job learning platform for many international students. Everything we do is grounded in our guiding principles to build and nurture quality relationships that allow us to place quality people in quality jobs.
              
              <br />
              
              <i>
                <b className="purple">One of the most authoritative and influential Chinese educational institutions in North America. &nbsp; </b>
                {/* <b className="purple">
                  Deep Learning and Natural Launguage Processing.
                </b> */}
              </i>
              {/* <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i> */}
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple qrcode-btn">connect </span>with me
            </p>

            <Button variant="primary" onClick={handleShow}>
              Click Here!
            </Button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Compass Education</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img src={qrCode} className="qrcode"></img>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            {/* <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/Soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/soumyajit4419/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
