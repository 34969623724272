import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import studentA from "../../Assets/Projects/student-a.png";
import studentF from "../../Assets/Projects/student-f.png";
import studentJ from "../../Assets/Projects/student-j.png";
import studentQ from "../../Assets/Projects/student-q.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our Recent <strong className="purple">Successful Stories </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are stories We've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={studentA}
              isBlog={false}
              title="MUFG Bank"
              description="Background: McMaster University Master of Finance Program, no Canadian experience.
              Through AI Package received an offer for Quantitative Risk Control Analyst role at MUFG Bank."
              ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={studentF}
              isBlog={false}
              title="Scotiabank"
              description="Background: Concordia University MBA Program, 1 Yr Front Office experience.
              Through AI Package received an offer for Product Control Analyst role at Scotia Bank."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={studentJ}
              isBlog={false}
              title="CIBC MELLON"
              description="Background: York University Bachelor of Economics, no Canadian experience.
              Through BO Package received an offer for Fund Accountant role at CIBC Mellon"
              ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={studentQ}
              isBlog={false}
              title="TD Bank"
              description="Background: Brock University MBA program, no Canadian experience.
              Through AI Package received a contract offer for Financial Analyst role at TD Bank."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={studentA}
              isBlog={false}
              title="Story 5"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={studentA}
              isBlog={false}
              title="Story 6"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
